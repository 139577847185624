import { ResetCSS } from '@pancakeswap/uikit'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import BigNumber from 'bignumber.js'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import FixedSubgraphHealthIndicator from 'components/SubgraphHealthIndicator'
import { ToastListener } from 'contexts/ToastsContext'
import useEagerConnect from 'hooks/useEagerConnect'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import type { AppProps } from 'next/app'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import Head from 'next/head'
import { Fragment } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { useStore, persistor } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { NextPage } from 'next'
import { Blocklist, Updaters } from '..'
import ErrorBoundary from '../components/ErrorBoundary'
import Menu from '../components/Menu'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'
import "./index.css"
import BannerPrice from '../components/Banner'

const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  useUserAgent()
  usePollCoreFarmData()
  useAccountEventListener()
  useSentryUser()
  return null
}

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const tabs = [
    { name: 'Swap', href: '/swap', current: true },
    { name: 'Limit', href: '/limit-orders', current: true },
    { name: 'Liquidity', href: '/liquidity', current: true },
  ]
  

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta
          name="description"
          content="Discover the most popular AMM on Arbitrum by user count! Earn Ximbia tokens through yield farming, then stake them in Syrup Pools to earn even more rewards. Participate in our Initial Farm Offering (IFO) to get exclusive access to new token launches. Plus, collect unique NFTs and enjoy a platform you can trust, all on ximbia for Arbitrum."
        />
        <meta name="theme-color" content="#1FC7D4" />
        <meta name="twitter:image" content="https://minio-s3.caprover.snotrasys.com/ximbia/logo.png" />
        <meta
          name="twitter:description"
          content="Discover the most popular AMM on Arbitrum by user count! Earn Ximbia tokens through yield farming, then stake them in Syrup Pools to earn even more rewards. Participate in our Initial Farm Offering (IFO) to get exclusive access to new token launches. Plus, collect unique NFTs and enjoy a platform you can trust, all on ximbia for Arbitrum."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ximbia" />
        <title>Ximbia</title>
      </Head>
      <Providers store={store}>
        <Blocklist>
          <GlobalHooks />
          <ResetCSS />
          <GlobalStyle />
          <GlobalCheckClaimStatus excludeLocations={[]} />
          <PersistGate loading={null} persistor={persistor}>
            <Updaters />
            { /* eslint-disable */}
           
            <div className="bg-black bg-center bg-cover"
      style={{ backgroundImage: `url(/bg.png)`}}>
             <div>
    

  </div>
            <App {...props} />
            </div>

          </PersistGate>
        </Blocklist>
      </Providers>

    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment
  return (
    <ProductionErrorBoundary>
      <Menu>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Menu>
      <EasterEgg iterations={2} />
      <ToastListener />
      <FixedSubgraphHealthIndicator />
    </ProductionErrorBoundary>
  )
}

export default MyApp
