import { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
/* eslint-disable */ export var useCountdown = function() {
    var date = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [
        2022,
        3,
        15,
        20
    ];
    var ref = useState("00"), timerDays = ref[0], setTimerDays = ref[1];
    var ref1 = useState("00"), timerHours = ref1[0], setTimerHours = ref1[1];
    var ref2 = useState("00"), timerMinutes = ref2[0], setTimerMinutes = ref2[1];
    var ref3 = useState("00"), timerSeconds = ref3[0], setTimerSeconds = ref3[1];
    var interval = useRef();
    var startTimer = function() {
        //const target = DateTime.utc(date[0],date[1],date[3],date[4]);
        var target = DateTime.utc(date[0], date[1], date[2], date[3]);
        setInterval(function() {
            var distance = target.diffNow() > 0 ? target.diffNow() : 0;
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
            var minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
            var seconds = Math.floor(distance % (1000 * 60) / 1000);
            setTimerDays(days.toString().padStart(2, "0"));
            setTimerHours(hours.toString().padStart(2, "0"));
            setTimerMinutes(minutes.toString().padStart(2, "0"));
            setTimerSeconds(seconds.toString().padStart(2, "0"));
            if (distance < 0) {
                clearInterval(interval.current);
            }
        }, 1000);
    };
    useEffect(function() {
        startTimer();
        return function() {
            clearInterval(interval.current);
        };
    }, []);
    return {
        timerDays: timerDays,
        timerHours: timerHours,
        timerMinutes: timerMinutes,
        timerSeconds: timerSeconds
    };
};
